import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PagesModule } from './pages/pages.module';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers } from './reducers';
import { EffectsModule } from '@ngrx/effects';
import { RouteHandlerModule } from './core/route-handler/route-handler.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatPaginatorIntl, MatDialogModule, GestureConfig, MatCheckboxModule } from '@angular/material';
import { MatPaginatorIntlSpanish } from 'tablaspa';
import { RequestInterceptorService } from './_servicios/jwt-authorization';
import { JwtHelper } from 'angular2-jwt';
import { AuthGuard } from './_servicios/auth-guard';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { IndexComponent } from './pages/index/index.component';
import { PageHeaderModule } from './core/page-header/page-header.module';
import { BreadcrumbsModule } from './core/breadcrumbs/breadcrumbs.module';
import { ServicioComunStimulsoft } from './_servicios/scomun-stimulsoft';


@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'elastic-ui' }),
    BrowserAnimationsModule,
    HttpClientModule,
    StoreModule.forRoot(reducers),
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 50 }) : [],
    EffectsModule.forRoot([]),
    LeafletModule.forRoot(),
    AppRoutingModule,
    CoreModule,
    PagesModule,
    PageHeaderModule,
    RouteHandlerModule,
    MatDialogModule,
    BreadcrumbsModule,
    MatCheckboxModule
  ],
  providers: [
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlSpanish },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true
    },
    JwtHelper,
    AuthGuard,
    ServicioComunStimulsoft,
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig }
  ],
  declarations: [AppComponent, IndexComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
