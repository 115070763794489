import { IndexComponent } from './pages/index/index.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './core/layout/layout.component';
import { authRoutes } from './pages/auth/auth.routing';
import { AuthGuard } from './_servicios/auth-guard';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: '',
    children: [
      ...authRoutes
    ]
  },
  {
    path: 'app',
    component: LayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: 'app/pages/dashboard/dashboard.module#DashboardModule'
      },
      // MÓDULO DE ADMINISTRACION
      {
        path: 'administracion',
        loadChildren: 'app/pages/administracion/administracion.module#AdministracionModule',
        data: {
          rolUsuario: ['Administrador']
        },
        canActivate: [AuthGuard]
      },
      // {
      //   path: 'dashboard/crm',
      //   loadChildren: 'app/pages/dashboard/dashboard-crm/dashboard-crm.module#DashboardCrmModule'
      // },
       // MÓDULO DE FACTURACION
       {
        path: 'facturacion',
        loadChildren: 'app/pages/facturacion/facturacion.module#FacturacionModule',
        data: {
          rolUsuario: ['Administrador', 'Facturacion']
        },
        canActivate: [AuthGuard]
      },
      // MÓDULO DE BOLSA DE PILOTOS
      {
        path: 'bolsapiloto',
        loadChildren: 'app/pages/bolsapiloto/bolsapiloto.module#BolsaPilotoModule'
      },
      // MÓDULO DE MANTENIMIENTOS
      {
        path: 'mantenimiento/bolsa-piloto',
        loadChildren: 'app/pages/mantenimiento/bolsapiloto/bolsapiloto.module#BolsaPilotoModule'
      },
      {
        path: 'mantenimiento/amura',
        loadChildren: 'app/pages/mantenimiento/amura/amura.module#AmuraModule'
      },
      {
        path: 'mantenimiento/factura',
        loadChildren: 'app/pages/mantenimiento/factura/factura.module#FacturaModule'
      },
      {
        path: 'index',
        component: IndexComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
