import { AfterViewInit, Component, OnInit } from '@angular/core';
import { JwtHelper } from 'angular2-jwt';

@Component({
  selector: 'vr-toolbar-user-button',
  templateUrl: './toolbar-user-button.component.html',
  styleUrls: ['./toolbar-user-button.component.scss']
})
export class ToolbarUserButtonComponent implements OnInit, AfterViewInit {
  correo: string;
  isOpen: boolean;

  constructor(private jwt: JwtHelper) { }

  ngOnInit() {
    if (localStorage.getItem('AccessToken')) {
      const tokenDecoded = this.jwt.decodeToken(localStorage.getItem('AccessToken'));
      this.correo = tokenDecoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata'];
    }
  }

  ngAfterViewInit() {
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.isOpen = false;
  }
}
