import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable()
export class ServicioAuth {

  url = environment.API_URL + '/api/auth/';

  constructor(private http: HttpClient) { }

  public login(modelo) {
    return this.http.post<any>(this.url + 'login', modelo);
  }

  public register(modelo) {
    return this.http.post<any>(this.url + 'register', modelo);
  }

  public logout() {
    return this.http.get<any>(this.url + 'logout');
  }
}
