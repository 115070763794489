import {throwError as observableThrowError} from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs/Rx'
import 'rxjs/operators/map';

import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaderResponse,
  HttpSentEvent,
  HttpProgressEvent,
  HttpUserEvent,
  HttpClient
} from '@angular/common/http';
import { MatSnackBar, MatDialog } from '@angular/material';
import { environment } from '../../environments/environment';

@Injectable()
export class RequestInterceptorService implements HttpInterceptor {

  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(public router: Router, private injector: Injector, public snackBar: MatSnackBar, public dialog: MatDialog) { }

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {

    return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } })
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent |
    HttpResponse<any> | HttpUserEvent<any>> {
    if (this.isRefreshingToken) {
      req = this.addToken(req, localStorage.getItem('RefreshToken'));
    } else {
      req = this.addToken(req, localStorage.getItem('AccessToken'));
    }
    return next.handle(req)
      .catch(error => {
        if (req.url.indexOf('/refresh') !== -1) {
          return this.logoutUser();
        }
        if (error instanceof HttpErrorResponse) {
          switch ((<HttpErrorResponse>error).status) {
            case 401:
              return this.handle401Error(req, next);
            case 403:
              return this.handle401Error(req, next);
            case 434:
              return this.handle434Error();
            case 0:
              return this.handle401Error(req, next);
          }
        } else {
          return observableThrowError(error);
        }
      });
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);
      const http = this.injector.get(HttpClient);
      return http.get<any>(environment.API_URL + 'auth/refresh', {})
        .switchMap(data => {
          localStorage.setItem('AccessToken', data.accesstoken.token);
          this.tokenSubject.next(data.accesstoken.token);
          this.isRefreshingToken = false;
          return next.handle(this.addToken(req, data.accesstoken.token));
        })
        .catch(error => {
          // If there is an exception calling 'refreshToken', bad news so logout.
          return this.logoutUser();
        })
        .finally(() => {
          this.isRefreshingToken = false;
        });
    } else {
      return this.tokenSubject
        .filter(token => token != null)
        .take(1)
        .switchMap(token => {
          return next.handle(this.addToken(req, localStorage.getItem('AccessToken')));
        });
    }
  }

  logoutUser() {
    this.dialog.closeAll();
    this.router.navigate(['/login']);
    return observableThrowError('');
  }

  handle434Error() {
    this.snackBar.open('No tiene suficientes privilegios para realizar esta acción', 'Error');
    return observableThrowError('');
  }
}
