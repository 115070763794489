import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { JwtHelper } from 'angular2-jwt';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private jwt: JwtHelper, public router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('AccessToken')) {
      const tokenDecoded = this.jwt.decodeToken(localStorage.getItem('AccessToken'));
      const roles = tokenDecoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      if (route.data.rolUsuario.length > 0) {
        if (Array.isArray(roles)) {
          if (roles.some(x => route.data.rolUsuario.includes(x))) {
            return true;
          } else {
            this.router.navigate(['/login']);
            return false;
          }
        } else {
          if (route.data.rolUsuario.filter(y => roles === y).length > 0) {
            return true;
          } else {
            this.router.navigate(['/login']);
            return false;
          }
        }
      } else {
        return true;
      }
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}
