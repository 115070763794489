import { Injectable } from '@angular/core';

@Injectable()
export class ServicioComun {

  private objeto: any;

  setData(datos: any) {
    this.objeto = datos;
  }

  clearData() {
    this.objeto = null;
  }

  getData() {
    return this.objeto;
  }
}
