import { Component, OnInit } from '@angular/core';
import { SidenavItem } from '../sidenav/sidenav-item/sidenav-item.model';
import * as fromRoot from '../../reducers/index';
import * as fromSidenav from '../sidenav/shared/sidenav.action';
import { Store } from '@ngrx/store';
import { ActivatedRoute, NavigationEnd, Router, NavigationStart } from '@angular/router';
import { SetCurrentlyOpenByRouteAction } from '../sidenav/shared/sidenav.action';
import { SelectLayoutAction, SetCardElevationAction } from '../layout/shared/layout.action';

@Component({
  selector: 'vr-route-handler',
  templateUrl: './route-handler.component.html',
  styleUrls: ['./route-handler.component.scss']
})
export class RouteHandlerComponent implements OnInit {

  constructor(
    private store: Store<fromRoot.State>,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    // Set Sidenav Currently Open on Page load
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.store.dispatch(new SetCurrentlyOpenByRouteAction(event.urlAfterRedirects));
      }
    });

    // You can use ?layout=beta to load the page with Layout Beta as default
    // Same with ?elevation=5 (anything from 0 to 24)
    this.route.queryParamMap.subscribe((params) => {
      const layout = params.get('layout');

      switch (layout) {
        case 'alpha': {
          this.store.dispatch(new SelectLayoutAction('alpha'));
          break
        }

        case 'beta': {
          this.store.dispatch(new SelectLayoutAction('beta'));
          break
        }

        case 'gamma': {
          this.store.dispatch(new SelectLayoutAction('gamma'));
          break
        }
      }

      const elevation = params.get('elevation');

      if (elevation) {
        this.store.dispatch(new SetCardElevationAction('card-elevation-z' + elevation))
      }
    });

    const Administracion = new SidenavItem({
      name: 'Administración',
      icon: 'person_pin',
      route: null,
      subItems: [],
      position: 1,
      roles: ['Administrador']
    });

    // lista de administracion
    const administracionSubItems = [
      new SidenavItem({
        name: 'Roles',
        route: '/app/administracion/role',
        parent: Administracion,
        subItems: [],
        position: 1,
      }),
      new SidenavItem({
        name: 'Usuarios',
        route: '/app/administracion/user',
        parent: Administracion,
        subItems: [],
        position: 1
      })
    ];

    const BolsaDePilotos = new SidenavItem({
      name: 'Bolsa de pilotos',
      icon: 'format_line_spacing',
      route: null,
      subItems: [],
      position: 1,
      roles: ['Administrador', 'Liquidacion']
    });

    const BolsaDePilotosSubItems = [
      new SidenavItem({
        name: 'Facturas de reparto',
        route: '/app/bolsapiloto/facturareparto',
        parent: BolsaDePilotos,
        subItems: [],
        position: 1
      }),
      new SidenavItem({
        name: 'Anticipos',
        route: '/app/bolsapiloto/anticipo',
        parent: BolsaDePilotos,
        subItems: [],
        position: 2
      }),
      new SidenavItem({
        name: 'Preliquidaciones',
        route: '/app/bolsapiloto/preliquidacion',
        parent: BolsaDePilotos,
        subItems: [],
        position: 3
      }),
      new SidenavItem({
        name: 'Liquidaciones',
        route: '/app/bolsapiloto/liquidacion',
        parent: BolsaDePilotos,
        subItems: [],
        position: 4
      }),
      new SidenavItem({
        name: 'Pagos',
        route: '/app/bolsapiloto/pago',
        parent: BolsaDePilotos,
        subItems: [],
        position: 5
      })
    ];

    //const Facturacion = new SidenavItem({
    //  name: 'Facturacion',
    //  icon: 'format_line_spacing',
    //  route: null,
    //  subItems: [],
    //  position: 1,
    //  roles: ['Administrador', 'Facturacion']
    //});

    //const FacturacionSubItems = [
    //  new SidenavItem({
    //    name: 'Albaranes',
    //    route: '/app/facturacion/albaran',
    //    parent: Facturacion,
    //    subItems: [],
    //    position: 1
    //  }),
    //  new SidenavItem({
    //    name: 'Facturas',
    //    route: '/app/facturacion/factura',
    //    parent: Facturacion,
    //    subItems: [],
    //    position: 2
    //  }),
    //  new SidenavItem({
    //    name: 'Abonos',
    //    route: '/app/facturacion/abono',
    //    parent: Facturacion,
    //    subItems: [],
    //    position: 2
    //  })
    //];

    const Mantenimiento = new SidenavItem({
      name: 'Mantenimiento',
      icon: 'format_line_spacing',
      route: null,
      subItems: [],
      position: 1,
      roles: ['Administrador', 'Mantenimiento']
    });

    const bolsapilotoconfig = new SidenavItem({
      name: 'Bolsa de piloto',
      route: null,
      subItems: [],
      parent: Mantenimiento,
      position: 1,
    });

    const bolsapilotoSubItems = [
      new SidenavItem({
        name: 'Retenciones',
        route: '/app/mantenimiento/bolsa-piloto/retencion',
        parent: bolsapilotoconfig,
        subItems: [],
        position: 1
      }),
      new SidenavItem({
        name: 'Tipos de anticipo',
        route: '/app/mantenimiento/bolsa-piloto/tipo-anticipo',
        parent: bolsapilotoconfig,
        subItems: [],
        position: 2
      }),
      //new SidenavItem({
      //  name: 'Plantilla Empleado',
      //  route: '/app/mantenimiento/factura/edit-factura/4',
      //  parent: bolsapilotoconfig,
      //  subItems: [],
      //  position: 3
      //}),
      new SidenavItem({
        name: 'Plantilla Liquidacion',
        route: '/app/mantenimiento/factura/edit-factura/3',
        parent: bolsapilotoconfig,
        subItems: [],
        position: 4
      })
    ];

    const amuraconfig = new SidenavItem({
      name: 'General',
      route: null,
      subItems: [],
      parent: Mantenimiento,
      position: 1,
    });

    const amuraSubItems = [
      new SidenavItem({
        name: 'Agencias marítimas',
        route: '/app/mantenimiento/amura/consignatario',
        parent: amuraconfig,
        subItems: [],
        position: 3
      }),
      new SidenavItem({
        name: 'Buques',
        route: '/app/mantenimiento/amura/buque',
        parent: amuraconfig,
        subItems: [],
        position: 4
      }),
      new SidenavItem({
        name: 'Nacionalidades',
        route: '/app/mantenimiento/amura/nacionalidad',
        parent: amuraconfig,
        subItems: [],
        position: 1
      }),
      new SidenavItem({
        name: 'Navieras',
        route: '/app/mantenimiento/amura/naviera',
        parent: amuraconfig,
        subItems: [],
        position: 2
      }),
      new SidenavItem({
        name: 'Pilotos contratistas',
        route: '/app/mantenimiento/amura/empleado',
        parent: amuraconfig,
        subItems: [],
        position: 7
      }),
      new SidenavItem({
        name: 'Puertos',
        route: '/app/mantenimiento/amura/seccion',
        parent: amuraconfig,
        subItems: [],
        position: 6
      }),
      new SidenavItem({
        name: 'Tipos de buque',
        route: '/app/mantenimiento/amura/tipo-buque',
        parent: amuraconfig,
        subItems: [],
        position: 5
      })
    ];

    //const facturacionconfig = new SidenavItem({
    //  name: 'Facturación',
    //  route: null,
    //  subItems: [],
    //  parent: Mantenimiento,
    //  position: 1,
    //});

    //const facturacionSubItems = [
    //  new SidenavItem({
    //    name: 'Formas de pago',
    //    route: '/app/mantenimiento/factura/forma-pago',
    //    parent: facturacionconfig,
    //    subItems: [],
    //    position: 1
    //  }),
    //  new SidenavItem({
    //    name: 'Tipos de factura',
    //    route: '/app/mantenimiento/factura/tipo-factura',
    //    parent: facturacionconfig,
    //    subItems: [],
    //    position: 2
    //  }),
    //  new SidenavItem({
    //    name: 'Tipos de impuesto',
    //    route: '/app/mantenimiento/factura/tipo-impuesto',
    //    parent: facturacionconfig,
    //    subItems: [],
    //    position: 3
    //  }),
    //  new SidenavItem({
    //    name: 'Conceptos facturables',
    //    route: '/app/mantenimiento/factura/tipo-detalle',
    //    parent: facturacionconfig,
    //    subItems: [],
    //    position: 4
    //  }),
    //  new SidenavItem({
    //    name: 'Plantilla Factura',
    //    route: '/app/mantenimiento/factura/edit-factura/1',
    //    parent: facturacionconfig,
    //    subItems: [],
    //    position: 5
    //  }),
    //  new SidenavItem({
    //    name: 'Plantilla Abono',
    //    route: '/app/mantenimiento/factura/edit-factura/2',
    //    parent: facturacionconfig,
    //    subItems: [],
    //    position: 6
    //  }),
    //  new SidenavItem({
    //    name: 'Plantilla Liquidacion',
    //    route: '/app/mantenimiento/factura/edit-factura/3',
    //    parent: facturacionconfig,
    //    subItems: [],
    //    position: 6
    //  }),
    //];

    Administracion.subItems.push(...administracionSubItems);
    BolsaDePilotos.subItems.push(...BolsaDePilotosSubItems);
    //Facturacion.subItems.push(...FacturacionSubItems);
    Mantenimiento.subItems.push(bolsapilotoconfig, /*facturacionconfig,*/ amuraconfig);

    bolsapilotoconfig.subItems.push(...bolsapilotoSubItems);
    amuraconfig.subItems.push(...amuraSubItems);
    //facturacionconfig.subItems.push(...facturacionSubItems);

    // Send the created Menu structure to Redux/ngrx (you only need to send the Top Level Item, all dropdown items will be added automatically)
    this.store.dispatch(new fromSidenav.AddSidenavItemAction(Administracion));
    this.store.dispatch(new fromSidenav.AddSidenavItemAction(BolsaDePilotos));
    //this.store.dispatch(new fromSidenav.AddSidenavItemAction(Facturacion));
    this.store.dispatch(new fromSidenav.AddSidenavItemAction(Mantenimiento));
  }

}
