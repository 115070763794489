import { JwtHelper } from 'angular2-jwt';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SidenavItem } from './sidenav-item/sidenav-item.model';
import { Observable } from 'rxjs';
import * as fromRoot from '../../reducers/index';
import { Store } from '@ngrx/store';

@Component({
  selector: 'vr-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {

  @Input('layout') layout: string; // Layout
  @Input('collapsed') collapsed: boolean;
  @Output('toggledSidenavCollapse') toggledSidenavCollapse = new EventEmitter();

  sidenavItems$: Observable<SidenavItem[]>;
  currentlyOpen$: Observable<SidenavItem[]>;
  roles: any;

  constructor(
    private store: Store<fromRoot.State>,
    private jwt: JwtHelper
  ) { }

  ngOnInit() {
    if (localStorage.getItem('AccessToken')) {
      const tokenDecoded = this.jwt.decodeToken(localStorage.getItem('AccessToken'));
      this.roles = tokenDecoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
    }
    this.sidenavItems$ = this.store.select(fromRoot.getSidenavItems);
    this.currentlyOpen$ = this.store.select(fromRoot.getSidenavCurrentlyOpen);
  }

  toggleSidenavCollapse() {
    this.toggledSidenavCollapse.emit();
  }

  ngOnDestroy() {
  }
}
