import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServicioAuth } from '../_servicios/sAuth';
import { ROUTE_TRANSITION } from '../../../app.animation';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'vr-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  animations: [...ROUTE_TRANSITION],
  host: { '[@routeTransition]': '' },
  providers: [ServicioAuth]
})
export class RegisterComponent implements OnInit {

  public form: FormGroup;

  constructor(private fb: FormBuilder, private router: Router, private servicio: ServicioAuth, public snackBar: MatSnackBar) {
    this.form = this.fb.group({
      email: ['', Validators.required],
      username: ['', Validators.required],
      password: ['', Validators.required],
      passwordConfirm: ['', Validators.required]
    });
  }

  ngOnInit() {
  }

  register() {
    this.servicio.register(this.form.value).subscribe(result => {
      if (result.success === true) {
        this.router.navigate(['/']);
      } else {
        this.snackBar.open(result.message, this.form.get('email').value, {
          duration: 2000,
        });
      }
    }, error => console.error(error));
  }

}
