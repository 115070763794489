import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServicioAuth } from '../_servicios/sAuth';
import { ROUTE_TRANSITION } from '../../../app.animation';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import * as $ from 'jquery';

@Component({
  selector: 'vr-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [...ROUTE_TRANSITION],
  host: { '[@routeTransition]': '' },
  providers: [ServicioAuth]
})
export class LoginComponent implements OnInit, AfterViewInit {

  public form: FormGroup;
  isLoadingResults: boolean;

  constructor(private fb: FormBuilder, private router: Router, private servicio: ServicioAuth,
    public snackBar: MatSnackBar) {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  ngOnInit() {
    localStorage.removeItem('AccessToken');
    localStorage.removeItem('RefreshToken');
  }

  ngAfterViewInit() {
    $('body').click();
  }

  login() {
    this.isLoadingResults = true;
    this.servicio.login(this.form.value).subscribe(result => {
      this.isLoadingResults = false;
      if (result.success === true) {
        if (result.accesstoken && result.refreshtoken) {
          localStorage.setItem('AccessToken', result.accesstoken.token);
          localStorage.setItem('RefreshToken', result.refreshtoken.token);
          this.router.navigate(['/app/index']);
        }
      } else {
        this.snackBar.open(result.message, this.form.get('email').value, {
          duration: 2000,
        });
      }
    }, error => console.error(error));
  }
}
