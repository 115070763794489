import { Injectable } from '@angular/core';

@Injectable()
export class ServicioComunStimulsoft {

  private data: any;

  setData(datos) {
    this.data = datos;
  }

  clearData() {
    this.data = null;
  }

  getData() {
    return this.data;
  }
}
